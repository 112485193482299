import React from 'react'
import PropTypes from 'prop-types'

import odd_one_out_cover from '../images/odd-one-out-cover.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
	  <h1>My sites</h1>
	  <p>A list of all the sites I own and maintain.</p>
	  <h3><a href="https://odd-one-out.serek.eu/">Odd One Out</a></h3>
          <span className="image main"><img src={odd_one_out_cover} alt="" /></span>
   	  <p>My site for anything technical and code related. Lots of different projects - anything from <a href="https://odd-one-out.serek.eu/tag/egpu/">external graphics cards</a>, <a href="https://odd-one-out.serek.eu/projects/diy-ambilight-without-pc/">DIY ambilight</a>, <a href="https://odd-one-out.serek.eu/projects/cryptocurrency-mining-bitcoin-litecoin-and-more/">cryptocurrency mining</a>, <a href="https://odd-one-out.serek.eu/tag/esp8266/">ESP8266 tinkering</a> and <a href="https://odd-one-out.serek.eu/tag/">more</a>.</p>
	  <p>The name “Odd One Out” was derived from an old testcase at work where we looked for deviations in production data - the odd data that stod out from the rest. The same can be said about my curiosity - I find something that stands out and geek out!</p>
	  <h3><a href="https://payments.serek.eu/">Payments</a></h3>
	  <p>The FinTech revolution is upon us and a perfect time for me to explore how to take advantage of it. Anything from credit cards, payment transfers and banks!</p>
	  <h1>About me</h1>
          <p>A dad with no free time, but in those rare instances when the planets align and I need to satisfy my curiosity, I blog my findings here.</p>
	  <p>You can contact me by social media:</p>
          <ul className="icons">
            <li><a href="https://twitter.com/PsiRek" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="https://github.com/psirek" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
